import React from 'react'

import LandingLayout from '@layouts/Landing'
import { Container, H1, H2, H3, H4 } from '@layouts/Landing/components'
import SEO from '@components/seo'
import { styled } from '@util/tw'

const P = styled('p', 'text-gray-700 my-2')

const Intercom = () => {
  return (
    <LandingLayout>
      <SEO
        title="Analyze conversations from Intercom for actionable insights."
        description="Sentimentor integrates with Intercom to sync conversations daily, and uses advanced analytics to make sense of customer communications."
      />
      <main>
        <div className="bg-blue-400">
          <Container className="flex-col">
            <H1 className="text-center">Analyze conversations from Intercom</H1>

            <H2 className="text-white text-center">
              Get ahead of spreadsheets, gut feelings and uncertainty in
              minutes.
            </H2>
          </Container>
        </div>
        <div>
          <Container className="flex-col">
            <H3>How to use Sentimentor</H3>
            <H4>What this app does</H4>
            <P>
              Sentimentor helps you make sense of live chat conversations. It
              uses powerful AI for analyzing customer chats. Uncover and track
              topics and sentiment in customer communications. Improve customer
              relations, identify potential issues, and monitor chat agent
              performance and customer satisfaction.
            </P>
            <P>
              Extract sentiment, emotionality, tone and entities present in
              conversations. See customer satisfaction from the right angle,
              start to finish. Identify hostile interactions, reward exemplary
              conduct on a per-conversation basis. Aggregate conversations to
              see volume, average sentiment and tone to inform marketing and
              communication decisions. Go beyond binary or star ratings, and get
              to the root of communication problems with laser focus. Change and
              shape behavior for the best results.
            </P>
            <P>
              Make sure you have access and permissions to add an App to your
              Intercom workspace.
            </P>
            <strong>1. Sign Up for Sentimentor</strong>​ ​
            <P>
              Name, email address and a password is all you need to get started.
            </P>
            <H4>How to use the app</H4>
            <strong>1. Add Intercom Source</strong>​ ​
            <P>
              Go to Sources &gt; Add &gt; Intercom to authorize Sentimentor with
              your Intercom workspace. This will start processing conversations
              right away to be viewable in minutes. Sentimentor will sync
              conversations every day while your plan is active.
            </P>
            <strong>2. Start Analyzing Conversations</strong>​ ​
            <P>
              Once Sentimentor has processed a conversation, you will be able to
              see it in the Conversations page. Any information associated with
              agents participating in the conversation will appear in the Agents
              page, and you can view concepts, entities and mentions that appear
              among conversations in the Entities page.
            </P>
            <strong>💡Additional use cases</strong>
            <P>
              Sentimentor allows you to analyze customer communications at a
              level never before seen. There are many other features to make
              life easier and save hours. Visit sentimentor.co for more
              information.
            </P>
          </Container>
        </div>
      </main>
    </LandingLayout>
  )
}

export default Intercom
